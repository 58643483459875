@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {

    --background: #ffffff;
    --foreground: #020817;
    --card: #ffffff;
    --card-foreground: #020817;
    --popover: #ffffff;
    --popover-foreground: #020817;
    --primary: #005E92;
    --primary-foreground: #f8fafc;
    --muted-secondary-foreground: #646464;
    --muted-secondary-file: #333333ab;
    --primary-hover: #003D5F;
    --primary-border: #1A6D93;
    --primary-file: #ECF5F9;
    --secondary: #F6F9FF;
    --secondary-foreground: #0f172a;
    --caution: #FFF9EA;
    --caution-border: #8C6530;
    --neutral: #F5F5F4;
    --neutral-border: #D6D3D1;
    --muted: #F6F6F6;
    --muted-foreground: #333333;
    --accent: #E6EFF4;
    --accent-foreground: #0f172a;
    --ring: #005E92;
    --destructive: #BA1C1C;
    --destructive-foreground:   #f8fafc;
    --border: #949494;
    --input: #949494;
    --radius: 0.75rem;
  }

  .dark {
    --background: #020817;
    --foreground: #f8fafc;
    --card: #020817;
    --card-foreground: #f8fafc;
    --popover: #020817;
    --popover-foreground: #f8fafc;
    --primary: #3b82f6;
    --primary-foreground: #0f172a;
    --secondary: #1e293b;
    --secondary-foreground: #f8fafc;
    --muted: #1e293b;
    --muted-foreground: #94a3b8;
    --accent: #1e293b;
    --accent-foreground: #f8fafc;
    --destructive: #7f1d1d;
    --destructive-foreground: #f8fafc;
    --border: #1e293b;
    --input: #1e293b;
    --ring: #1d4ed8;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  ::-ms-reveal{
    display: none;
  }
}
